<template>
    <head>
        <!-- Bootstrap icons-->
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css" rel="stylesheet" type="text/css" />
        <!-- Google fonts-->
        <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic,700italic" rel="stylesheet" type="text/css" />
        <!-- Core theme CSS (includes Bootstrap)-->
        <!--<link href="{{ asset('css/styles.css') }}" rel="stylesheet" />-->
        <link rel="stylesheet" id="font-awesome-css" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css?ver=5.7.0" type="text/css" media="all">

    </head>
</template>

<script>
export default {
    name: "Head",
};
</script>