import { createStore } from 'vuex'

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as search from "@/store/modules/Search";
import * as cart from "@/store/modules/Cart";
import * as mediaThreshold from "@/store/modules/MediaThreshold";

export default createStore({

  modules: {
    auth,
    user,
    search,
    cart,
    mediaThreshold,
  },

});
