import * as API from "@/services/API";
export default {
    async getCart() {
        return API.apiClient.get("/ctd-cart");
    },
    getCartSize() {
        return API.apiClient.get("/ctd-cart/get-cart-size/");
    },
    removeFromShoppingCart(id) {
        return API.apiClient.delete(`/book/${id}`);
    },
    emptyShoppingCart(){
        return API.apiClient.post("/ctd-cart/empty-cart/");
    },

};