import { getError } from "@/utils/helpers";
import CartService from "@/services/CartService";

export const namespaced = true;

function setCartData(commit, response) {
  commit("SET_CART", response.data);
  commit("SET_LOADING", false);
}

export const state = {
  //cartContents: JSON.parse(localStorage.getItem('cart')),
  cartContents: 0,
  loading: false,
  error: null,
};

export const mutations = {
  // SET_SIZE(state, size) {
  //   state.size = size;
  // },
  SET_CART(state, cartContents) {
    state.cartContents = cartContents;
    //localStorage.setItem('cart', JSON.stringify(cartContents));
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) { 
    state.error = error;
  },
};

export const actions = {
  // getCartSize({ commit }) {
  //   commit("SET_LOADING", true);
  //   CartService.getCartSize()
  //     .then((response) => {
  //       setPaginatedMessages(commit, response);
  //     })
  //     .catch((error) => {
  //       commit("SET_LOADING", false);
  //       commit("SET_ERROR", getError(error));
  //     });
  // },
  getCart({ commit }) {
    commit("SET_LOADING", true);
    CartService.getCart()
      .then((response) => {
        setCartData(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
};

export const getters = {
  // size: (state) => {
  //   return state.size;
  // },
  cartContents: (state) => {
    return state.cartContents;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },


};
