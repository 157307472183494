import * as API from "@/services/API";

export default { 
//   postMessage(payload) {
//     return API.apiClient.post("/messages", payload);
//   },
//   paginateMessages(link) {
//     return API.apiClient.get(link);
//   },
    //keyword

    async searchBooks(keyword) {
        return API.apiClient.get("/book/search", keyword);
    },

    async searchMedia(payload) {
        //return API.apiClient.get("/book/search", keyword);
        return API.apiClient.get(`/search/${payload}`);
    },
    
    // async deleteBook(id) {
    //     return API.apiClient.post("/book/delete", id);
    // }

};
