<template>
  	<header>
		<nav class="navbar navbar-expand-lg navbar-light ">
			<div class="container-fluid">
				<router-link to="/" class="navbar-brand">
					<!--{{ config('app.name', 'Laravel') }}-->
					<img :src="'/img/new-logo-small.png'" class="d-none d-sm-block" alt="goldstone books logo" >
					<img :src="'/img/mobile-logo.png'" class="d-block d-sm-none" alt="goldstone books logo" >
				</router-link>

				<div class="ms-auto me-3 mt-1 d-flex d-inline-flex d-lg-none"> 
					<div v-if="authUser">
						<router-link to="/" class="account-link mx-2 mt-1">
							<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#333366" class="bi bi-person-fill" viewBox="0 0 16 16">
								<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
							</svg>
							</span>
						</router-link>
						<LogoutMobile />
					</div>
					<router-link
						v-else
						to="/login"
						class="mx-2 mt-1"
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#333366" class="bi bi-person-circle" viewBox="0 0 16 16">
							<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
							<path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
						</svg>
					</router-link>

				</div>

				<button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="#" >
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class=" collapse navbar-collapse " id="navbarSupportedContent">
					<ul class="navbar-nav ms-auto ms-auto">
				
						<!-- /* admin menu */ -->
						<!-- <li class="nav-item mx-3 my-1" v-if="isAdmin">
							<router-link to="/register">Register</router-link>
						</li> -->
						<li class="nav-item mx-3 my-1" v-if="isAdmin">
							<router-link to="/media-thresholds">Thresholds</router-link>
						</li>

					</ul>
					<ul class="navbar-nav ms-auto">
						<!-- <div class="d-flex navbar-nav d-none d-lg-inline-flex"> -->
						<div class="d-flex navbar-nav d-none d-lg-inline-flex">
							<!-- <div class="btn btn-large btn-outline-primary ms-3" v-if="authUser"> -->
							<div class="ms-3" v-if="authUser">
								<router-link to="/" class="account-link me-3">{{ authUser.name }}</router-link>
								<Logout />
							</div>
							<router-link
								v-else
								to="/login"
								class="btn btn-large btn-outline-primary ms-3"
							>
							<span>Login</span>
							<!-- <LoginIcon class="w-6 h-6 text-black" /> -->
							</router-link> 
					
						</div>
					</ul>
				</div>
			</div>
		</nav>
  	</header>
</template>

<script>
import { mapGetters } from "vuex";
import Logout from "@/components/Logout";
import LogoutMobile from "@/components/LogoutMobile";
//import HomeIcon from "@/components/icons/HomeIcon";
import LoginIcon from "@/components/icons/LoginIcon";

export default {
  name: "Header",
  components: {
    Logout,
    LogoutMobile,
    //HomeIcon,
    LoginIcon,
  },
  data() {
    return {

    }
  },
  methods: {

  },
  computed: {
    ...mapGetters("auth", ["authUser", "isAdmin"]),
    ...mapGetters({cartContents: 'cart/cartContents'}),
  },
  created(){
    this.$store.dispatch("cart/getCart");
  },

};
</script>
