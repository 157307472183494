import { getError } from "@/utils/helpers";
import MediaThresholdService from "@/services/MediaThresholdService";

export const namespaced = true; 

function setMediaThresholds(commit, response) {
  //commit("SET_SIZE", response.data);
  commit("SET_MEDIA_THRESHOLDS", response.data);
  commit("SET_LOADING", false);
}

export const state = {
  mediaThresholds: [],
  selectedMediaThreshold: [],
  loading: false,
  error: null,
};

export const mutations = {
  // SET_SIZE(state, size) {
  //   state.size = size;
  // },
  SET_MEDIA_THRESHOLDS(state, mediaThresholds) {
    state.mediaThresholds = mediaThresholds;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {  
    state.error = error;
  },
};

export const actions = {
  // getCartSize({ commit }) {
  //   commit("SET_LOADING", true);
  //   CartService.getCartSize()
  //     .then((response) => {
  //       setPaginatedMessages(commit, response);
  //     })
  //     .catch((error) => {
  //       commit("SET_LOADING", false);
  //       commit("SET_ERROR", getError(error));
  //     });
  // },
  getMediaThresholds({ commit }) {
    commit("SET_LOADING", true);
    MediaThresholdService.getMediaThresholds()
      .then((response) => {
        setMediaThresholds(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  postMediaThresholds({commit}, payload) {
    commit("SET_LOADING", true);
    MediaThresholdService.postMediaThresholds(payload)
      .then((response) => {
        setThresholds(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  updateMediaThresholds({commit}, payload){
    commit("SET_LOADING", true);
    MediaThresholdService.updateMediaThresholds(payload)
      .then((response) => {
        setMediaThresholds(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
};

export const getters = {

  mediaThresholds: (state) => {
    return state.mediaThresholds;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
