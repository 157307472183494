<template>
  <div id="app">
    <Head />
    <Header />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Header from "@/components/Header";

export default {
  name: "app",
  components: {
    Head,
    Header,
  },
};
</script>
