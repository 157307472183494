import { getError } from "@/utils/helpers";
import BookService from "@/services/BookService";

export const namespaced = true;

function setBooks(commit, response) {
  commit("SET_BOOKS", response.data);
  //commit("SET_META", response.data.meta);
  //commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export const state = {
  books: [],
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_BOOKS(state, books) {
    state.books = books;
  },
  // SET_META(state, meta) {
  //   state.meta = meta;
  // },
  // SET_LINKS(state, links) {
  //   state.links = links;
  // },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  searchBooks({ commit }, keyword) {
    commit("SET_LOADING", true);
    BookService.searchBooks(keyword)
      .then((response) => {
        setBooks(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
};

export const getters = { 
  messages: (state) => {
    return state.messages;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
