<template>
  <button
    type="button"
    @click="logout"
    class="btn btn-large btn-outline-primary"
  >
  <span class="me-1 ms-1">Logout</span>
    <LogoutIcon />
  </button>
</template>

<script>
import LogoutIcon from "@/components/icons/LogoutIcon";
export default {
  components: { LogoutIcon },
  name: "Logout",
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
