<template>
    <button 
        class="btn p-0 mx-2"
        type="button"
        @click="logout"
    >
        <LogoutIconMobile />
    </button>
  </template>
  
  <script>
  import LogoutIconMobile from "@/components/icons/LogoutIconMobile";
  export default {
    components: { LogoutIconMobile },
    name: "LogoutMobile",
    methods: {
      logout() {
        this.$store.dispatch("auth/logout");
      },
    },
  };
  </script>