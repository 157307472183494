import * as API from "@/services/API";

export default {
  getMediaThreshold(id) {
    return API.apiClient.get("/media-thresholds", id);
  },
  getMediaThresholds() {
    return API.apiClient.get("/media-thresholds"); 
  },
  postMediaThresholds(payload) {
    return API.apiClient.post("/media-thresholds", payload);
  },
  updateMediaThresholds(payload) {
    let threshold_id = payload.thresholdId;

    return API.apiClient.put("/media-thresholds/" + threshold_id, payload); 
  },

};
