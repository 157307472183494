import * as API from "@/services/API";

export default {

    data () {
        return {

        };
    },
    methods: {
        sendOfferEmail(user_offer) {
            
            API.apiClient
            .post("/email/offer-created-mail", user_offer)
            .then((response) => {
                console.log(response);
            });
        },
        offerReceivedEmail(user_offer) {

            API.apiClient
            .post("/email/offer-received-mail", user_offer)
            .then((response) => {
                console.log(response);
            });
            
        },
        offerSortedEmail(user_offer) {

            API.apiClient
            .post("/email/offer-sorted-mail", user_offer)
            .then((response) => {
                console.log(response);
            });
            
        },
    },
    computed: {

    },
}
