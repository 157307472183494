import "./assets/css/app.css"
import "./assets/css/bookbuyer-styles.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'sweetalert2/dist/sweetalert2.min.css';

import * as bootstrap from 'bootstrap';
import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';
import { createApp } from "vue";
import { StreamBarcodeReader } from "vue-barcode-reader";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from './mixins.js';

const momentPlugin = {
    install(app) {
        // Attach moment to Vue 3 global properties, to make it globally available.
        // https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
        app.config.globalProperties.$moment = moment;
    }
}

const myApp = createApp(App);

myApp.use(momentPlugin);
myApp.use(router);
myApp.use(store);
myApp.use(bootstrap);
myApp.use(VueSweetalert2);
//Vue.config.productionTip = false;
myApp.mixin(mixins); 
myApp.use(StreamBarcodeReader);
myApp.mount('#app');